<template>
	<b-modal
	title="Seleccionar Mesa"
	hide-footer 
	size="lg"
	id="select-mesa">
		<salon
		@seleccionarMesa="seleccionarMesa"
		modo="seleccionar-mesa"
		:salon="salon"></salon>
	</b-modal>	
</template>
<script>
import orders from '@/mixins/orders'
export default {
	mixins: [orders],
	components: {
		Salon: () => import('@/components/salon/Salon'),
	},
	computed: {
		salones() {
			return this.$store.state.salon.models 
		},
		salon() {
			return this.salones.find(salon => {
				return salon.id == this.model.salon_id 
			})
		},
	},
	methods: {
		seleccionarMesa(mesa) {
			console.log('seleccionarMesa')
			console.log(mesa)
			this.model.mesa_id = mesa.id 
			this.model.mesa = mesa 
		}
	}
}
</script>